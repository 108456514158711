import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Col, Row } from "reactstrap";

const Pagination = ({
  children,
  data,
  totalRecords,
  totalPages,
  pageSizeValue,
  setPageSizeValue,
  setOnValueSearchBar,
  setCurrentPageValue,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(pageSizeValue);
  const [pageSizesArray] = useState([6, 9, 12, 15]);
  const [onValueSearch, setOnValueSearch] = useState("");

  useEffect(() => {
    setPageSize(pageSizeValue);
  }, [pageSizeValue]);

  const handlePageSizeChange = (value) => {
    setPageSize(value);
    setPageSizeValue(value);
    setCurrentPageValue(1);
  };

  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => {
      const newPage = Math.max(prevPage - 1, 1);
      setCurrentPageValue(newPage);
      return newPage;
    });
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => {
      const newPage = Math.min(prevPage + 1, totalPages);
      setCurrentPageValue(newPage);
      return newPage;
    });
  };

const renderPageNumbers = () => {
  const pageNumbers = [];
  const maxVisiblePages = 5; // Max number of visible pages
  const halfVisiblePages = Math.floor(maxVisiblePages / 2);
  let startPage = Math.max(currentPage - halfVisiblePages, 1);
  let endPage = Math.min(currentPage + halfVisiblePages, totalPages);

  if (currentPage <= halfVisiblePages) {
    endPage = Math.min(maxVisiblePages, totalPages);
  } else if (currentPage + halfVisiblePages >= totalPages) {
    startPage = Math.max(totalPages - maxVisiblePages + 1, 1);
  }

  if (startPage > 1) {
    pageNumbers.push(
      <li key="start-ellipsis" className="page-item">
        <Link
          className="page-link"
          onClick={(e) => {
            e.preventDefault();
            setCurrentPage(startPage - 1);
            setCurrentPageValue(startPage - 1);
          }}
          to="#"
        >
          ...
        </Link>
      </li>
    );
  }

  for (let i = startPage; i <= endPage; i++) {
    pageNumbers.push(
      <li key={i} className={`page-item ${currentPage === i ? "active" : ""}`}>
        <Link
          className="page-link"
          onClick={(e) => {
            e.preventDefault();
            setCurrentPage(i);
            setCurrentPageValue(i);
          }}
          to="#"
        >
          {i}
        </Link>
      </li>
    );
  }

  if (endPage < totalPages) {
    pageNumbers.push(
      <li key="end-ellipsis" className="page-item">
        <Link
          className="page-link"
          onClick={(e) => {
            e.preventDefault();
            setCurrentPage(endPage + 1);
            setCurrentPageValue(endPage + 1);
          }}
          to="#"
        >
          ...
        </Link>
      </li>
    );
  }

  return pageNumbers;
};

  const startRecord = (currentPage - 1) * pageSize + 1;
  const endRecord = Math.min(currentPage * pageSize, totalRecords);

  return (
    <>
      <div className="pagination-wrapper">
        <Row className="mb-1">
          <form>
            <Row className="d-flex gap-2 justify-content-between align-items-center">
              <Col sm={4}>
                Items per page: &nbsp;
                <select
                  className="border-gray p-1 shadow-none text-dark"
                  value={pageSize}
                  onChange={(e) => handlePageSizeChange(Number(e.target.value))}
                >
                  {pageSizesArray?.map((size) => (
                    <option key={size} value={size}>
                      {size}
                    </option>
                  ))}
                </select>
              </Col>
              <Col sm={4} className="d-flex justify-content-end">
                <input
                  type="text"
                  placeholder="Search"
                  className="form-control"
                  value={onValueSearch}
                  onChange={(e) => {
                    setOnValueSearch(e.target.value);
                    setOnValueSearchBar(e.target.value);
                    setCurrentPageValue(1);
                  }}
                />
              </Col>
            </Row>
          </form>
        </Row>
        <div>{children}</div>
        {data?.length > 0 && (
          <Row className="mt-2 d-flex">
            <div className="pagination-info col-sm justify-content-start">
              Showing {startRecord} to {endRecord} of {totalRecords} items
            </div>
            <div className="col-sm justify-content-end">
              <ul className="pagination pagination-sm justify-content-end">
                <li
                  className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
                >
                  <Link
                    className="page-link"
                    onClick={(e) => {
                      e.preventDefault();
                      handlePreviousPage();
                    }}
                    to="#"
                  >
                    {"<"}
                  </Link>
                </li>
                {renderPageNumbers()}
                <li
                  className={`page-item ${
                    currentPage === totalPages ? "disabled" : ""
                  }`}
                >
                  <Link
                    className="page-link"
                    onClick={(e) => {
                      e.preventDefault();
                      handleNextPage();
                    }}
                    to="#"
                  >
                    {">"}
                  </Link>
                </li>
              </ul>
            </div>
          </Row>
        )}
      </div>
    </>
  );
};

export default Pagination;