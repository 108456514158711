export const Enter = "Enter";
export const ItemsPerPage = "Items per page";
export const Showing = "Showing";
export const of = "of";
export const Results = "Results";
export const to = "to";
export const Search = "Search";
export const TagLine = "Training for a Better Tomorrow";
export const aboutUs = "About Us";
export const aboutUsDetailOne = "AITSIL SPV PVT. LTD. is a leading training and skill development organization dedicated to empowering India's youth with the skills they need to secure better employment opportunities. As a training partner of two major government skill development initiatives—PMKVY (Pradhan Mantri Kaushal Vikas Yojana) and JSDM (Jharkhand Skill Development Mission)—our mission is to bridge the skill gap and contribute to India's economic growth by creating a workforce that is skilled, employable, and ready to meet the demands of various industries.";
export const aboutUsDetailTwo = "With training centres in Gujarat and Jharkhand, we aim to provide vocational education to unemployed individuals and school/college dropouts, enhancing their employability and equipping them with industry-relevant skills.";
