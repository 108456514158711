import React from 'react'

const NotFound = () => {
  return (
    <div className='lost d-flex justify-content-center align-items-center bg-gray'>
      404. Page Not Found. :(
    </div>
  )
}

export default NotFound
