import React from "react";
import { ClipLoader } from "react-spinners";
import { css } from "@emotion/react";

const Loader = ({ loading }) => {
  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `;
  return (
    <div className={`${loading && "loader-overlay"}`}>
      <ClipLoader
        color="#36D7B7"
        className={`${loading && "loader-overlay1"}`}
        loading={loading}
        size={50}
      />
    </div>
  );
};

export default Loader;
