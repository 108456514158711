import {Routes, Route, BrowserRouter} from 'react-router-dom'
import Home from "../components/Home"
import Header from '../Layout/Header'
import About from '../components/About'
import Footer from '../Layout/Footer'
import Gallery from '../components/Gallery'
import Contact from '../components/Contact'
import NotFound from '../Layout/NotFound'
import Career from '../components/Career'
import CompanyDetails from '../components/CompanyDetails'

const MainRoutes = () => {
  return (
  <BrowserRouter>
    <Header/>
    <Routes>
      <Route path='/' element={<Home />} />
      <Route path='/companyDetails' element={<CompanyDetails />} />
      <Route path='/about' element={<About />} />
      <Route path='/gallery' element={<Gallery />} />
      <Route path='/contact' element={<Contact />} />
      <Route path='/career' element={<Career />} />
      <Route path='/*' element={<NotFound />} />
    </Routes>
    <Footer />
  </BrowserRouter>
  )
}

export default MainRoutes
