import React from "react";
import { Link } from "react-router-dom";
import { aboutUs } from "../comman/constant";
import image1 from "../../src/images/slider-bg.jpg";

const About = () => {
  document.title = "About | Aitsil SPV";
  return (
    <>
      <section className="breadcrumb-area pt-10px bg-gray">
        <div className="container">
          <div className="breadcrumb-content d-flex flex-wrap align-items-center justify-content-between pb-10px">
            <div className="section-heading">
              <h2 className="fs-24 text-black">About</h2>
            </div>
            <ul className=" generic-list-item-black generic-list-item-arrow d-flex flex-wrap align-items-center">
              <li>
                <Link
                  onClick={() => (window.location.href = "/")}
                  to="/"
                  className="text-black"
                >
                  Home
                </Link>
              </li>
              <li>About</li>
            </ul>
          </div>
          {/* end breadcrumb-content */}
        </div>
        {/* end container */}
      </section>
      <section className="about-area pt-60px overflow-hidden">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="about-content pb-5">
                <div className="section-heading">
                  <h2 className="section__title pb-3 lh-50">{aboutUs}</h2>
                  <p className="section__desc">
                    AITSIL SPV PVT. LTD. is a leading training and skill
                    development organization dedicated to empowering India's
                    youth with the skills they need to secure better employment
                    opportunities. As a training partner of two major government
                    skill development initiatives—PMKVY (Pradhan Mantri Kaushal
                    Vikas Yojana) and JSDM (Jharkhand Skill Development
                    Mission)—our mission is to bridge the skill gap and
                    contribute to India's economic growth by creating a
                    workforce that is skilled, employable, and ready to meet the
                    demands of various industries.
                  </p>
                  <p className="section__desc mt-4">
                    With training centres in Gujarat and Jharkhand, we aim to
                    provide vocational education to unemployed individuals and
                    school/college dropouts, enhancing their employability and
                    equipping them with industry-relevant skills.
                  </p>
                </div>
                {/* end section-heading */}
              </div>
              {/* end about-content */}
            </div>
            {/* end col-lg-6 */}
            <div className="col-lg-6 generic-main">
              <div className="generic-img-box generic-img-box1 generic-img-box-layout-2">
                <div className="img__item img__item-1">
                  <img
                    className="lazy"
                    src="images/img-loading.png"
                    data-src="images/img15.jpg"
                    alt="About image"
                  />
                  <div className="generic-img-box-content">
                    <h3 className="fs-24 font-weight-semi-bold pb-1">
                      20,000+
                    </h3>
                    <span>Trained Students</span>
                  </div>
                </div>
                <div className="img__item img__item-2">
                  <img
                    className="lazy"
                    src="images/img-loading.png"
                    data-src="images/img16.jpg"
                    alt="About image"
                  />
                  <div className="generic-img-box-content">
                    <h3 className="fs-24 font-weight-semi-bold pb-1">
                      10,000+
                    </h3>
                    <span>Centers</span>
                  </div>
                </div>
                <div className="img__item img__item-3">
                  <img
                    className="lazy"
                    src="images/img-loading.png"
                    data-src="images/img17.jpg"
                    alt="About image"
                  />
                  <div className="generic-img-box-content">
                    <h3 className="fs-24 font-weight-semi-bold pb-1">400+</h3>
                    <span>Job Placements</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* future goals */}
      <section
        className="feature-area pb-20px  mt-2 pt-4"
        style={{ backgroundColor: "rgba(47, 72, 92)" }}
      >
        <div className="container">
          <h2 className="text-white text-center">Future Goals</h2>
          <div className="d-flex justify-content-center mb-3">
            <span className="section-divider" />
          </div>
          <div className="row ">
            <div className="col-lg-3 responsive-column-half">
              <div className="info-box" style={{ height: 450 }}>
                <div className="info-overlay" />
                <div className="icon-element mx-auto shadow-sm">
                  <svg
                    className="svg-icon-color-1"
                    width={41}
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    viewBox="0 0 490.667 490.667"
                    xmlSpace="preserve"
                  >
                    <g>
                      <g>
                        <path
                          d="M245.333,85.333c-41.173,0-74.667,33.493-74.667,74.667s33.493,74.667,74.667,74.667S320,201.173,320,160
                                          C320,118.827,286.507,85.333,245.333,85.333z M245.333,213.333C215.936,213.333,192,189.397,192,160
                                          c0-29.397,23.936-53.333,53.333-53.333s53.333,23.936,53.333,53.333S274.731,213.333,245.333,213.333z"
                        />
                      </g>
                    </g>
                    <g>
                      <g>
                        <path
                          d="M394.667,170.667c-29.397,0-53.333,23.936-53.333,53.333s23.936,53.333,53.333,53.333S448,253.397,448,224
                                          S424.064,170.667,394.667,170.667z M394.667,256c-17.643,0-32-14.357-32-32c0-17.643,14.357-32,32-32s32,14.357,32,32
                                          C426.667,241.643,412.309,256,394.667,256z"
                        />
                      </g>
                    </g>
                    <g>
                      <g>
                        <path
                          d="M97.515,170.667c-29.419,0-53.333,23.936-53.333,53.333s23.936,53.333,53.333,53.333s53.333-23.936,53.333-53.333
                                          S126.933,170.667,97.515,170.667z M97.515,256c-17.643,0-32-14.357-32-32c0-17.643,14.357-32,32-32c17.643,0,32,14.357,32,32
                                          C129.515,241.643,115.157,256,97.515,256z"
                        />
                      </g>
                    </g>
                    <g>
                      <g>
                        <path
                          d="M245.333,256c-76.459,0-138.667,62.208-138.667,138.667c0,5.888,4.779,10.667,10.667,10.667S128,400.555,128,394.667
                                          c0-64.704,52.629-117.333,117.333-117.333s117.333,52.629,117.333,117.333c0,5.888,4.779,10.667,10.667,10.667
                                          c5.888,0,10.667-4.779,10.667-10.667C384,318.208,321.792,256,245.333,256z"
                        />
                      </g>
                    </g>
                    <g>
                      <g>
                        <path
                          d="M394.667,298.667c-17.557,0-34.752,4.8-49.728,13.867c-5.013,3.072-6.635,9.621-3.584,14.656
                                          c3.093,5.035,9.621,6.635,14.656,3.584C367.637,323.712,380.992,320,394.667,320c41.173,0,74.667,33.493,74.667,74.667
                                          c0,5.888,4.779,10.667,10.667,10.667c5.888,0,10.667-4.779,10.667-10.667C490.667,341.739,447.595,298.667,394.667,298.667z"
                        />
                      </g>
                    </g>
                    <g>
                      <g>
                        <path
                          d="M145.707,312.512c-14.955-9.045-32.149-13.845-49.707-13.845c-52.928,0-96,43.072-96,96
                                          c0,5.888,4.779,10.667,10.667,10.667s10.667-4.779,10.667-10.667C21.333,353.493,54.827,320,96,320
                                          c13.675,0,27.029,3.712,38.635,10.752c5.013,3.051,11.584,1.451,14.656-3.584C152.363,322.133,150.741,315.584,145.707,312.512z"
                        />
                      </g>
                    </g>
                  </svg>
                </div>
                <h3 className="info__title">Expand Training Programs</h3>
                <p className="info__text">
                  Introduce new training programs in emerging sectors like
                  digital marketing, AI, robotics, and green energy.
                </p>
              </div>
              {/* end info-box */}
            </div>
            <div className="col-lg-3 responsive-column-half">
              <div className="info-box" style={{ height: 450 }}>
                <div className="info-overlay" />
                <div className="icon-element mx-auto shadow-sm">
                  <svg
                    className="svg-icon-color-2"
                    viewBox="0 0 74 74"
                    width={45}
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="m31.841 26.02a1 1 0 0 1 -.52-1.855l2.59-1.57a1 1 0 1 1 1.037 1.71l-2.59 1.57a1 1 0 0 1 -.517.145z" />
                    <path d="m57.42 58.09a.985.985 0 0 1 -.294-.045l-20.09-6.179a1 1 0 0 1 -.546-1.5l26.054-40.382-39.324 38.55a1 1 0 0 1 -1.087.208l-16.76-7.03a1 1 0 0 1 -.131-1.777l11.358-6.871a1 1 0 0 1 1.035 1.711l-9.675 5.853 14.334 6.013 39.106-38.341-20.363 12.316a1 1 0 0 1 -1.037-1.716l27.709-16.747a1 1 0 0 1 .372-.14s0 0 0 0a.986.986 0 0 1 .156-.013 1 1 0 0 1 .609.206l.079.067a1 1 0 0 1 .312.713 1.023 1.023 0 0 1 -.023.227l-10.814 54.073a1 1 0 0 1 -.98.8zm-18.533-7.747 17.769 5.466 9.572-47.844z" />
                    <path d="m23.221 31.23a1 1 0 0 1 -.519-1.856l2.53-1.53a1 1 0 0 1 1.036 1.712l-2.531 1.53a1 1 0 0 1 -.516.144z" />
                    <path d="m28.7 72h-.072a1 1 0 0 1 -.894-.74l-6.178-23.184a1 1 0 1 1 1.931-.515l5.438 20.389 7.488-17.435a1 1 0 1 1 1.838.789l-8.629 20.096a1 1 0 0 1 -.922.6z" />
                    <path d="m28.709 72a1 1 0 0 1 -.736-1.677l16.092-17.515a1 1 0 0 1 1.473 1.354l-16.093 17.515a1 1 0 0 1 -.736.323z" />
                  </svg>
                </div>
                <h3 className="info__title">Reach More States</h3>
                <p className="info__text">
                  Expand our training network to additional states, increasing
                  accessibility to skill development opportunities.
                </p>
              </div>
              {/* end info-box */}
            </div>
            {/* end col-lg-3 */}
            <div className="col-lg-3 responsive-column-half">
              <div className="info-box" style={{ height: 450 }}>
                <div className="info-overlay" />
                <div className="icon-element mx-auto shadow-sm">
                  <svg
                    className="svg-icon-color-2"
                    viewBox="0 0 74 74"
                    width={45}
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="m31.841 26.02a1 1 0 0 1 -.52-1.855l2.59-1.57a1 1 0 1 1 1.037 1.71l-2.59 1.57a1 1 0 0 1 -.517.145z" />
                    <path d="m57.42 58.09a.985.985 0 0 1 -.294-.045l-20.09-6.179a1 1 0 0 1 -.546-1.5l26.054-40.382-39.324 38.55a1 1 0 0 1 -1.087.208l-16.76-7.03a1 1 0 0 1 -.131-1.777l11.358-6.871a1 1 0 0 1 1.035 1.711l-9.675 5.853 14.334 6.013 39.106-38.341-20.363 12.316a1 1 0 0 1 -1.037-1.716l27.709-16.747a1 1 0 0 1 .372-.14s0 0 0 0a.986.986 0 0 1 .156-.013 1 1 0 0 1 .609.206l.079.067a1 1 0 0 1 .312.713 1.023 1.023 0 0 1 -.023.227l-10.814 54.073a1 1 0 0 1 -.98.8zm-18.533-7.747 17.769 5.466 9.572-47.844z" />
                    <path d="m23.221 31.23a1 1 0 0 1 -.519-1.856l2.53-1.53a1 1 0 0 1 1.036 1.712l-2.531 1.53a1 1 0 0 1 -.516.144z" />
                    <path d="m28.7 72h-.072a1 1 0 0 1 -.894-.74l-6.178-23.184a1 1 0 1 1 1.931-.515l5.438 20.389 7.488-17.435a1 1 0 1 1 1.838.789l-8.629 20.096a1 1 0 0 1 -.922.6z" />
                    <path d="m28.709 72a1 1 0 0 1 -.736-1.677l16.092-17.515a1 1 0 0 1 1.473 1.354l-16.093 17.515a1 1 0 0 1 -.736.323z" />
                  </svg>
                </div>
                <h3 className="info__title">Increase Job Placements</h3>
                <p className="info__text">
                  Aim to double our placement numbers by partnering with more
                  companies and industries.
                </p>
              </div>
              {/* end info-box */}
            </div>
            {/* end col-lg-3 */}
            <div className="col-lg-3 responsive-column-half">
              <div className="info-box" style={{ height: 450 }}>
                <div className="info-overlay" />
                <div className="icon-element mx-auto shadow-sm">
                  <svg
                    className="svg-icon-color-3"
                    viewBox="0 0 74 74"
                    width={50}
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="m23.8 23.84a1 1 0 0 1 -.294-1.956l5.96-1.84a1 1 0 0 1 .59 1.912l-5.956 1.844a.981.981 0 0 1 -.3.04z" />
                    <path d="m37 43.84a1.009 1.009 0 0 1 -.37-.071l-34-13.561a1 1 0 0 1 .07-1.883l5.29-1.64a1 1 0 0 1 .592 1.91l-2.592.8 31.01 12.368 25.9-10.325a1.015 1.015 0 0 1 .12-.057l4.98-1.981-31-9.593-2.165.669a1 1 0 1 1 -.59-1.912l2.46-.76a1 1 0 0 1 .59 0l34 10.52a1 1 0 0 1 .075 1.884l-7.49 2.982a.95.95 0 0 1 -.12.058l-26.39 10.521a1.009 1.009 0 0 1 -.37.071z" />
                    <path d="m13.069 27.161a1 1 0 0 1 -.3-1.956l5.951-1.841a1 1 0 1 1 .59 1.911l-5.95 1.841a1.013 1.013 0 0 1 -.291.045z" />
                    <path d="m16.8 47.849a1 1 0 0 1 -1-1v-12.064a1 1 0 1 1 2 0v12.064a1 1 0 0 1 -1 1z" />
                    <path d="m57.188 47.849a1 1 0 0 1 -1-1v-12.064a1 1 0 0 1 2 0v12.064a1 1 0 0 1 -1 1z" />
                    <path d="m37 56.239c-11.884 0-21.193-4.123-21.193-9.386a1 1 0 1 1 2 0c0 3.493 7.882 7.386 19.193 7.386s19.193-3.893 19.193-7.386a1 1 0 1 1 2 0c0 5.263-9.309 9.386-21.193 9.386z" />
                    <path d="m63.393 44.387a1 1 0 0 1 -1-1v-10.2l-25.529-3.5a1 1 0 1 1 .272-1.982l26.393 3.615a1 1 0 0 1 .864.991v11.076a1 1 0 0 1 -1 1z" />
                    <path d="m66.406 49.5h-5.687a1 1 0 0 1 -.978-1.211l.736-3.411a3.156 3.156 0 0 1 6.171 0l.736 3.411a1 1 0 0 1 -.978 1.211zm-4.448-2h3.209l-.474-2.2a1.157 1.157 0 0 0 -2.261 0z" />
                  </svg>
                </div>
                <h3 className="info__title">Innovate Training Delivery</h3>
                <p className="info__text">
                  Incorporate new technologies like virtual reality and
                  e-learning platforms to enhance the training experience.
                </p>
              </div>
              {/* end info-box */}
            </div>
            {/* end col-lg-3 */}
          </div>
          {/* end row */}
        </div>
        {/* end container */}
      </section>

      {/*Service list*/}
      <section className="testimonial-area pt-40px">
        <div className="container">
          <div className="section-heading text-center">
            <h2 className="section__title">Our Services</h2>
            <span className="section-divider" />
          </div>
        </div>

        {/* Carousel wrapper */}
        <>
          {/* Carousel wrapper */}
          <div
            id="carouselMultiItemExample"
            data-mdb-carousel-init=""
            className="carousel slide carousel-dark text-center"
            data-mdb-ride="carousel"
          >
            {/* Controls */}
            <div className="d-flex justify-content-center mb-4">
              <button
                data-mdb-button-init=""
                className="carousel-control-prev position-relative"
                type="button"
                data-mdb-target="#carouselMultiItemExample"
                data-mdb-slide="prev"
              >
                <span
                  className="carousel-control-prev-icon"
                  aria-hidden="true"
                />
                <span className="visually-hidden">Previous</span>
              </button>
              <button
                data-mdb-button-init=""
                className="carousel-control-next position-relative"
                type="button"
                data-mdb-target="#carouselMultiItemExample"
                data-mdb-slide="next"
              >
                <span
                  className="carousel-control-next-icon "
                  aria-hidden="true"
                />
                <span className="visually-hidden">Next</span>
              </button>
            </div>
            {/* Inner */}
            <div className="carousel-inner py-4">
              {/* Single item */}
              <div className="carousel-item active">
                <div className="container">
                  <div className="row gap-4 justify-content-center">
                    <div className="col-lg-5 responsive-column-half py-4">
                      <div
                        className="card card-item hover-y"
                        style={{ height: 250 }}
                      >
                        <div className="card-body align-items-center">
                          <h5 className="mb-3">Vocational Training Programs</h5>
                          <ul class="generic-list-item overview-list-item">
                            <li className="">
                              <i class="la la-circle mr-1 text-black"></i>
                              Offering skill development courses in various
                              sectors like retail, IT, manufacturing,
                              healthcare, and more, aligned with industry
                              standards.
                            </li>
                            <li className="">
                              <i class="la la-circle mr-1 text-black"></i>
                              Curriculum designed to cater to the practical
                              needs of the job market, making students
                              job-ready.
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-5 responsive-column-half py-4">
                      <div
                        className="card card-item hover-y"
                        style={{ height: 250 }}
                      >
                        <div className="card-body align-items-center">
                          <h5 className="mb-3">Placement Assistance</h5>
                          <ul class="generic-list-item overview-list-item">
                            <li className="">
                              <i class="la la-circle mr-1 text-black"></i>
                              Dedicated placement cell that works closely with
                              industry partners to facilitate job placements.
                            </li>
                            <li className="">
                              <i class="la la-circle mr-1 text-black"></i>
                              Organizing job fairs and campus recruitment drives
                              to connect trainees with potential employers.
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Single item */}
              <div className="carousel-item">
                <div className="container">
                  <div className="row gap-4 justify-content-center">
                    <div className="col-lg-5 responsive-column-half py-4">
                      <div
                        className="card card-item hover-y"
                        style={{ height: 250 }}
                      >
                        <div className="card-body align-items-center">
                          <h5 className="mb-3">
                            Career Guidance and Counselling
                          </h5>
                          <ul class="generic-list-item overview-list-item">
                            <li className="">
                              <i class="la la-circle mr-1 text-black"></i>
                              Providing students with career counseling to help
                              them choose the right training programs based on
                              their interests and market demand.
                            </li>
                            <li className="">
                              <i class="la la-circle mr-1 text-black"></i>
                              Offering support in resume building, interview
                              preparation, and soft skills development.
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-5 responsive-column-half py-4">
                      <div
                        className="card card-item hover-y"
                        style={{ height: 250 }}
                      >
                        <div className="card-body align-items-center">
                          <h5 className="mb-3">
                            Government Initiative Partner
                          </h5>
                          <ul class="generic-list-item overview-list-item">
                            <li className="">
                              <i class="la la-circle mr-1 text-black"></i>
                              As a training partner of PMKVY and JSDM, we align
                              our courses with national skill development
                              initiatives to support government efforts in
                              improving employability.
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Inner */}
          </div>
          {/* Carousel wrapper */}
        </>

        {/* Carousel wrapper */}
      </section>

      <section className="our-mission-area pt-40px bg-gray">
        <div className="container-fluid mb-4">
          <h2 className="section__title mb-3 text-center">Our Mission</h2>
          <div className="row">
            <div className="col-lg-4 responsive-column-half">
              <div className="card card-item hover-y" style={{ height: 300 }}>
                <div className="card-body d-flex align-items-center">
                  <div className="flex-shrink-0">
                    <img
                      src="images/small-img-2.jpg"
                      alt="card image"
                      className="img-fluid rounded-full"
                    />
                  </div>
                  <div className="pl-4">
                    <h5 className="card-title pt-4 pb-2">Empowering Youth</h5>
                    <p className="card-text">
                      We are committed to providing vocational training to
                      unemployed and dropout youth, enabling them to acquire
                      industry-relevant skills.{" "}
                    </p>
                    {/* <div className="btn-box mt-20px">
                    <a href="become-a-teacher.html" className="btn theme-btn theme-btn-sm lh-30"><i className="la la-user mr-1"></i>Start Teaching</a>
                  </div> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 responsive-column-half">
              <div className="card card-item hover-y" style={{ height: 300 }}>
                <div className="card-body d-flex align-items-center">
                  <div className="flex-shrink-0">
                    <img
                      src="images/small-img-3.jpg"
                      alt="card image"
                      className="img-fluid rounded-full"
                    />
                  </div>
                  <div className="pl-4">
                    <h5 className="card-title pt-4 pb-2">Driving Employment</h5>
                    <p className="card-text">
                      We work towards improving employability by facilitating
                      job placements through our dedicated placement cell.{" "}
                    </p>
                    {/* <div className="btn-box mt-20px">
                    <a href="for-business.html" className="btn theme-btn theme-btn-sm lh-30"><i className="la la-briefcase mr-1"></i>Get Aduca for Business</a>
                  </div> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 responsive-column-half">
              <div className="card card-item hover-y" style={{ height: 300 }}>
                <div className="card-body d-flex align-items-center">
                  <div className="flex-shrink-0">
                    <img
                      src="images/small-img-4.jpg"
                      alt="card image"
                      className="img-fluid rounded-full"
                    />
                  </div>
                  <div className="pl-4">
                    <h5 className="card-title pt-4 pb-2">Nation Building</h5>
                    <p className="card-text">
                      Contributing to India's economic development by enhancing
                      the skills of the workforce and reducing unemployment.{" "}
                    </p>
                    {/* <div className="btn-box mt-20px">
                    <a href="admission.html" className="btn theme-btn theme-btn-sm lh-30"><i className="la la-file-text-o mr-1"></i>Start Learning</a>
                  </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* location */}
      <section className="client-logo-area pt-60px position-relative overflow-hidden mb-5">
        <span className="stroke-shape stroke-shape-1" />
        <span className="stroke-shape stroke-shape-2" />
        <span className="stroke-shape stroke-shape-3" />
        <span className="stroke-shape stroke-shape-4" />
        <span className="stroke-shape stroke-shape-5" />
        <span className="stroke-shape stroke-shape-6" />
        <div className="container">
          <div className="section-heading text-center">
            <h2 className="section__title mb-3">Our Locations</h2>
          </div>
          {/* end section-heading */}
          <div className="mt-40px">
            <div className="row">
              <div className="card2 col-lg-6 col-sm-12">
                <div className="card-body">
                  <h4>ARVALLI</h4>
                  <span className="section-divider section--divider" />
                  <p>
                    T-5/ Bandhan Arcade Meghraj Road Village:modasa (m)
                    District: Sabar Kanth 383315
                  </p>
                  <ul className="list-unstyled2 ml-3">
                    <li>Agriculture Extension Service Provider</li>
                    <li>Drone Service Technician</li>
                    <li>Kisan Drone Operator</li>
                  </ul>
                </div>
              </div>
              <div className="card2 w-100 col-lg-6 col-sm-12">
                <div className="card-body">
                  <h4>BANAS KANTHA</h4>
                  <span className="section-divider section--divider" />
                  <p>
                    12/28 Gitanjali Owners Association, C O Narshibhai P
                    Bhatol,Peles Road Pln, Village : Palanpur, Tal
                  </p>
                  <ul className="list-unstyled2 ml-3">
                    <li>Agriculture Extension Service Provider</li>
                    <li>Drone Service Technician</li>
                    <li>Kisan Drone Operator</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="card2 col-lg-6 col-sm-12">
                <div className="card-body">
                  <h4>GANDHINAGAR</h4>
                  <span className="section-divider section--divider" />
                  <p>
                    Shantiniketan Vidhya Vihar High School, Svadaeshi Chali,
                    Navjivan Road, Village: Kalol,taluka: Kalol
                  </p>
                  <ul className="list-unstyled2 ml-3">
                    <li>Agriculture Extension Service Provider</li>
                    <li>Drone Service Technician</li>
                    <li>Kisan Drone Operator</li>
                  </ul>
                </div>
              </div>
              <div className="card2 w-100 col-lg-6 col-sm-12">
                <div className="card-body">
                  <h4>MAHESANA</h4>
                  <span className="section-divider section--divider" />
                  <p>
                    T 308, Singapore Hub, Opp Akshar Dham Flat, Vill: Maheshna,
                    Taluka: Mehsana, Dist : Mahesana,Gujarat
                  </p>
                  <ul className="list-unstyled2 ml-3">
                    <li>Agriculture Extension Service Provider</li>
                    <li>Drone Service Technician</li>
                    <li>Kisan Drone Operator</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="card2 col-lg-6 col-sm-12">
                <div className="card-body">
                  <h4>PATAN</h4>
                  <span className="section-divider section--divider" />
                  <p>
                    Sheetla Shopping Bijo Mal Dukan No 17-24 Radhanpur Village
                    Radhanpur Distt Patan
                  </p>
                  <ul className="list-unstyled2 ml-3">
                    <li>Agriculture Extension Service Provider</li>
                    <li>Drone Service Technician</li>
                    <li>Kisan Drone Operator</li>
                  </ul>
                </div>
              </div>
              <div className="card2 w-100 col-lg-6 col-sm-12">
                <div className="card-body">
                  <h4>SABAR KANTHA</h4>
                  <span className="section-divider section--divider" />
                  <p>
                    Block D Shop No. 87, Ashoka Vatika S J Road,
                    Vill:himmatnagar, Dist : Sabarkantha, Gujrat-383001
                  </p>
                  <ul className="list-unstyled2 ml-3">
                    <li>Agriculture Extension Service Provider</li>
                    <li>Drone Service Technician</li>
                    <li>Kisan Drone Operator</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="card2 col-lg-6 col-sm-12">
                <div className="card-body">
                  <h4>AHMADABAD</h4>
                  <span className="section-divider section--divider" />
                  <p>
                    3rd Floor, 301, Harvilla Complex, Jk Park, Gota Road,
                    Chandlodiya.
                  </p>
                  <ul className="list-unstyled2 ml-3">
                    <li>Bridal, Fashion and Portfolio Makeup Artist</li>
                    <li>Database Administrator</li>
                    <li>Drone Service Technician</li>
                    <li>Electronics Machine Maintenance Executive</li>
                    <li>GRAPHIC DESIGNER</li>
                    <li>Sewing Machine Operator - Knits</li>
                  </ul>
                </div>
              </div>
              <div className="card2 w-100 col-lg-6 col-sm-12">
                <div className="card-body">
                  <h4>DADRA AND NAGAR HAVELI</h4>
                  <span className="section-divider section--divider" />
                  <p>
                    122/23, Amli Vegetable Market, Near Datt Mandir, Amli -
                    396230
                  </p>
                  <ul className="list-unstyled2 ml-3">
                    <li>Additive Manufacturing (3D Printing)</li>
                    <li>Bridal, Fashion and Portfolio Makeup Artist</li>
                    <li>Drone Service Technician</li>
                    <li>Electronics Machine Maintenance Executive</li>
                    <li>GRAPHIC DESIGNER</li>
                    <li>Sewing Machine Operator - Knits</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="card2 col-lg-6 col-sm-12">
                <div className="card-body">
                  <h4>DAMAN</h4>
                  <span className="section-divider section--divider" />
                  <p>
                    15-155/a, Main Road Khariwad, Nani Daman, daman 396210 Nani
                    Daman (dmc)
                  </p>
                  <ul className="list-unstyled2 ml-3">
                    <li>Additive Manufacturing (3D Printing)</li>
                    <li>Bridal, Fashion and Portfolio Makeup Artist</li>
                    <li>Drone Service Technician</li>
                    <li>Electronics Machine Maintenance Executive</li>
                    <li>GRAPHIC DESIGNER</li>
                    <li>Sewing Machine Operator - Knits</li>
                  </ul>
                </div>
              </div>
              <div className="card2 w-100 col-lg-6 col-sm-12">
                <div className="card-body">
                  <h4>KUTCH</h4>
                  <span className="section-divider section--divider" />
                  <p>
                    R S No 6/2, Paiki Plot No 5 To 8, Shree Hari Complex, Behind
                    Shubham Furniture, Mirzpar Road, Mirzap
                  </p>
                  <ul className="list-unstyled2 ml-3">
                    <li>Additive Manufacturing (3D Printing)</li>
                    <li>Bridal, Fashion and Portfolio Makeup Artist</li>
                    <li>Drone Service Technician</li>
                    <li>Electronics Machine Maintenance Executive</li>
                    <li>GRAPHIC DESIGNER</li>
                    <li>Sewing Machine Operator</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="card2 col-lg-6 col-sm-12">
                <div className="card-body">
                  <h4>MORBI</h4>
                  <span className="section-divider section--divider" />
                  <p>
                    3rd &amp; 4th Floor Ghanshyam Market 2, Ravapar Road, Nr V
                    mart, Morbi, Gujarat-363641
                  </p>
                  <ul className="list-unstyled2 ml-3">
                    <li>Additive Manufacturing (3D Printing)</li>
                    <li>Bridal, Fashion and Portfolio Makeup Artist</li>
                    <li>Domestic Data Entry Operator</li>
                    <li>Electronics Machine Maintenance Executive</li>
                    <li>Drone Service Technician</li>
                    <li>GRAPHIC DESIGNER</li>
                    <li>Sewing Machine Operator</li>
                  </ul>
                </div>
              </div>
              <div className="card2 w-100 col-lg-6 col-sm-12">
                <div className="card-body">
                  <h4>SURENDRANAGAR</h4>
                  <span className="section-divider section--divider" />
                  <p>3rd Floor, Planet Square - 3 80 Ft Road, Surendranagar</p>
                  <ul className="list-unstyled2 ml-3">
                    <li>Additive Manufacturing (3D Printing)</li>
                    <li>Bridal, Fashion and Portfolio Makeup Artist</li>
                    <li>Drone Service Technician</li>
                    <li>Electronics Machine Maintenance Executive</li>
                    <li>GRAPHIC DESIGNER</li>
                    <li>Sewing Machine Operator - Knits</li>
                    <li>Two Wheeler Service Technician</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="card2 col-lg-6 col-sm-12">
                <div className="card-body">
                  <h4>Dhanbad (Jharkhand)</h4>
                  <span className="section-divider section--divider" />
                  <p>
                    Balaji Kutir Nr. St Xaviers International School Navadih
                    Dhanbad Jaharkhand
                  </p>
                  <ul className="list-unstyled2 ml-3">
                    <li>Fashion Designer (FD)</li>
                    <li>Shampling Tailor (ST)</li>
                    <li>General Duety Assistant (GDA)</li>
                    <li>Two Wheeler Service Technician</li>
                    <li>Four Wheeler Service Technician</li>
                    <li>field Engineer (RACW)</li>
                    <li>Reteil Sales Associate (RSA)</li>
                    <li>Fitter Micanical Assembly (FMA)</li>
                  </ul>
                </div>
              </div>
              <div className="card2 w-100 col-lg-6 col-sm-12">
                <div className="card-body">
                  <h4>Jamshedpr (Jharkhand)</h4>
                  <span className="section-divider section--divider" />
                  <p>Om Tower Dimna Chawk NH 33 Mango Jamshedpur 831012</p>
                  <ul className="list-unstyled2 ml-3">
                    <li>Sewing Machine Operator</li>
                    <li>Shampling Tailor (ST)</li>
                    <li>General Duety Assistant (GDA)</li>
                    <li>Buety Therapist</li>
                    <li>Four Wheeler Service Technician</li>
                    <li>field Engineer (RACW)</li>
                    <li>Solar Panel Instalation Technician</li>
                  </ul>
                </div>
              </div>
            </div>
            {/* <p className="client-logo-item">BANAS KANTHA</p>
          <p className="client-logo-item">GANDHINAGAR</p>
          <p className="client-logo-item">MAHESANA</p>
          <p className="client-logo-item">PATAN</p>
          <p className="client-logo-item">SABAR KANTHA</p>
          <p className="client-logo-item">AHMADABAD</p>
          <p className="client-logo-item">DADRA AND
              NAGAR HAVELI</p>
          <p className="client-logo-item">DAMAN</p>
          <p className="client-logo-item">KUTCH</p>
          <p className="client-logo-item">MORBI</p>
          <p className="client-logo-item">SURENDRANAGAR</p>
          <p className="client-logo-item">Dhanbad (Jharkhand)</p>
          <p className="client-logo-item">Jamshedpr (Jharkhand)</p> */}
          </div>
        </div>
        {/* end container */}
      </section>
      {/* why choose aitsil */}
      <section className="about-area pt-40px overflow-hidden">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="about-content pb-5">
                <div className="section-heading">
                  <h2 className="section__title">
                    Why Choose AITSIL SPV PVT. LTD.
                  </h2>
                  <span className="section-divider" />
                  <ul class="generic-list-item overview-list-item">
                    <li className="">
                      <i class="la la-check mr-1 text-black"></i>
                      <span className="text-secondary f-w-bold mr-2">
                        <b>Experienced Faculty :</b>
                      </span>
                      Our trainers are industry experts with extensive
                      experience in their respective fields, ensuring that
                      students receive practical, hands-on training.
                    </li>
                    <li className="">
                      <i class="la la-check mr-1 text-black"></i>
                      <span className="text-secondary f-w-bold mr-2">
                        <b>Comprehensive Curriculum :</b>
                      </span>
                      Courses designed with input from industry partners to meet
                      current market needs.
                    </li>
                    <li className="">
                      <i class="la la-check mr-1 text-black"></i>
                      <span className="text-secondary f-w-bold mr-2">
                        <b>High Placement Rate :</b>
                      </span>
                      Strong placement record with a network of industry
                      partners providing job opportunities.
                    </li>
                    <li className="">
                      <i class="la la-check mr-1 text-black"></i>
                      <span className="text-secondary f-w-bold mr-2">
                        <b>Government Accreditation :</b>
                      </span>
                      Partnering with reputable government skill development
                      initiatives ensures recognition and quality training.
                    </li>
                    <li className="">
                      <i class="la la-check mr-1 text-black"></i>
                      <span className="text-secondary f-w-bold mr-2">
                        <b>Focus on Holistic Development: </b>
                      </span>
                      We not only provide technical skills but also emphasize
                      personality development and communication skills.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* impact */}
      <section className="cat-area pt-40px pb-40px bg-gray position-relative">
        <span className="ring-shape ring-shape-1" />
        <span className="ring-shape ring-shape-2" />
        <span className="ring-shape ring-shape-3" />
        <span className="ring-shape ring-shape-4" />
        <span className="ring-shape ring-shape-5" />
        <span className="ring-shape ring-shape-6" />
        <span className="ring-shape ring-shape-7" />
        {/* end container */}
        <div className="container text-center">
          <h4 className="ribbon ribbon-lg ">Impact on Society</h4>
          <h4 className="section__desc text-muted mt-2">
            AITSIL SPV PVT. LTD. is not just a training provider; we are a
            social change agent. By equipping the youth with the skills they
            need, we help uplift communities, reduce unemployment, and
            contribute to the nation's economic progress. Our work supports the
            government's vision of "Skill India" by creating a skilled workforce
            that meets the needs of a rapidly evolving economy.
          </h4>
        </div>
      </section>

      {/* partner with us */}
      <section class="register-area section-padding dot-bg overflow-hidden">
        <div class="container">
          <div class="row">
            <div class="col-lg-5">
              <div class="card card-item">
                <div class="card-body">
                  <h3 class="fs-24 font-weight-semi-bold pb-2">
                    Company Details
                  </h3>
                  <div class="divider">
                    <span></span>
                  </div>
                  <ul class="generic-list-item overview-list-item">
                    <li className="">
                      <i class="la la-circle mr-1 text-black"></i>
                      <span className="text-secondary f-w-bold mr-2">
                        <b>Company Name :</b>
                      </span>
                      AITSIL SPV PVT. LTD.
                    </li>
                    <li className="">
                      <i class="la la-circle mr-1 text-black"></i>
                      <span className="text-secondary f-w-bold mr-2">
                        <b>Type :</b>
                      </span>
                      Private Limited Company
                    </li>
                    <li className="">
                      <i class="la la-circle"></i>
                      <span className="text-secondary f-w-bold mr-2">
                        <b>industry :</b>
                      </span>
                      Vocational Training, Skill Development, Education
                    </li>
                    <li className="">
                      <i class="la la-circle"></i>
                      <span className="text-secondary f-w-bold mr-2">
                        <b>Headquarters :</b>
                      </span>
                      C-Block 1st Floor , Deval Gurukrupa Ghatlodiya Ahmedabad
                      Gujarat 382481
                    </li>
                    <li className="">
                      <i class="la la-circle"></i>
                      <span className="text-secondary f-w-bold mr-2">
                        <b>Established :</b>
                      </span>
                      2019
                    </li>
                    <li className="d-flex justify-content-start">
                      <i class="la la-circle"></i>
                      <span className="text-secondary f-w-bold mr-2">
                        <b>Website :</b>
                      </span>
                      <a
                        href="https://www.aitsil.in"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        www.aitsil.in
                      </a>
                      {/* 
                      <Link target="_blank" to="www.aitsil.in">
                        www.aitsil.in
                      </Link> */}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-lg-6 ml-auto">
              <div class="register-content">
                <div class="section-heading">
                  <h5 class="ribbon ribbon-lg mb-2">Partner with Us</h5>
                  <h2 class="section__title">
                    Get ahead with Learning Paths. Stay Sharp.
                  </h2>
                  <span class="section-divider"></span>
                  <p class="section__desc">
                    AITSIL SPV PVT. LTD. is always open to collaborating with
                    government agencies, corporate partners, and NGOs to expand
                    the reach of skill development initiatives and contribute to
                    the socioeconomic development of the country. Together, we
                    can make a difference.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
