import axios from "axios";
// old dev url
// const baseURL = "https://erp-be-dge5.onrender.com"
// const baseImageURL = "https://erp-be-dge5.onrender.com/uploads"
// old uat 1 url
// const baseURL = "https://erp-be-1.onrender.com/";
// const baseImageURL = "https://erp-be-1.onrender.com";
// new uat 1 url
// const baseURL = "https://erp-be-j2ah.onrender.com/";
// const baseImageURL = "https://erp-be-j2ah.onrender.com/";
// new live url
const baseURL = "https://api.aitsil.in/"
const baseImageURL ="https://trymefashionhub-s3-bucket.s3.ap-south-1.amazonaws.com/aitsil-data/";

const authServices = axios.create({
  baseURL: baseURL,
  headers: {
    Accept: "application/json",
  },
});
const authMultiPart = axios.create({
  baseURL: baseURL,
  headers: {
    Accept: "multipart/form-data",
  },
});
export const imageBaseUrl = baseImageURL;
export const fileUploads = async (data) => {
  const response = await authMultiPart.post(`/api/common/fileUploads`, data);
  return response?.data;
};

export const addCareer = async (data) => {
  const response = await authServices.post(`/api/career`, data);
  return response?.data;
};

export const addInquiry = async (data) => {
  const response = await authServices.post(`/api/candidate/inquiry`, data);
  return response?.data;
};
export const listOfCenter = async () => {
  const response = await authServices.get(`/api/center/openCenterList`);
  return response?.data;
};
export const listOfPosition = async (data) => {
  const response = await authServices.post(`/api/common/listOfPosition`, data);
  return response?.data;
};
export const listOfCourse = async (data) => {
  const response = await authServices.post(`/api/course/listOfCourse`, data);
  return response?.data;
};
export const centerWiseCourse = async (data) => {
  const response = await authServices.post(
    `/api/center/centerWiseCourse`,
    data
  );
  return response?.data;
};
export const listOfCategory = async (data) => {
  const response = await authServices.post(
    `/api/category/listOfCategory`,
    data
  );
  return response?.data;
};
export const listOfCategoryImage = async (data) => {
  const response = await authServices.post(
    `/api/category/listOfCategoryImage`,
    data
  );
  return response?.data;
};
export const listOfCategoryImagePage = async (data) => {
  const response = await authServices.post(
    `/api/category/listCategoryImage`,
    data
  );
  return response?.data;
};
export const courseList = async (data) => {
  const response = await authServices.post(`/api/course/listOfCourse`, data);
  return response?.data;
};
export const jobList = async (data) => {
  const response = await authServices.post(
    `/api/common/listOfJobPosition`,
    data
  );
  return response?.data;
};
