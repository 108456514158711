import React, { useState, useEffect } from "react";
import {
  imageBaseUrl,
  listOfCategoryImage,
  listOfCategoryImagePage,
} from "../Api/ApiRoute";
import { css } from "@emotion/react";
import { ClipLoader } from "react-spinners";
import image1 from "../images/slider-bg.jpg";
import { Link } from "react-router-dom";
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const Gallery = () => {
  document.title = "Gallery | Aitsil SPV";
  const [categories, setCategories] = useState([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [selectedCategoryImages, setSelectedCategoryImages] = useState([]);
  const [loadingCategories, setLoadingCategories] = useState(true);
  const [loadingImages, setLoadingImages] = useState(true);
  const [page, setPage] = useState(6);
  const [totalRecordsCount, setTotalRecordsCount] = useState(0);
  const [visibleImages, setVisibleImages] = useState(6);
  const [imageErrors, setImageErrors] = useState({});
  const getProfileImageSrc = (image, imageError) => {
    const apiImageUrl = `${imageBaseUrl}${image}`;
    const localImageUrl = image1;
    return imageError ? localImageUrl : apiImageUrl;
  };
  const handleImageError = (id) => {
    // Update the imageErrors state to mark the image as errored
    setImageErrors((prev) => ({
      ...prev,
      [id]: true,
    }));
  };
  useEffect(() => {
    fetchCategories();
    fetchAllImages();
  }, []);

  const fetchCategories = async () => {
    try {
      setLoadingCategories(true);
      const categoriesData = await listOfCategoryImage({});
      setCategories(categoriesData?.data?.listOfCategoryImage);
    } catch (error) {
      console.error("Error fetching categories:", error);
    } finally {
      setLoadingCategories(false);
    }
  };

  const fetchAllImages = async () => {
    try {
      setLoadingImages(true);
      const payload = {
        pageSize: page,
        pageNumber: 1,
      };
      const categoryImageData = await listOfCategoryImagePage(payload);
      setSelectedCategoryImages(categoryImageData?.data?.listOfCategoryImage);
      setTotalRecordsCount(categoryImageData?.data?.totalRecordsCount);
      setPage(categoryImageData?.data?.numberOfRows);
    } catch (error) {
      console.error("Error fetching images:", error);
    } finally {
      setLoadingImages(false);
    }
  };

  const handleLoadMore = async (value) => {
    try {
      setLoadingImages(true);
      const payload = {
        pageSize: value,
        pageNumber: 1,
      };
      let categoryImageData;
      if (selectedCategoryId === null) {
        categoryImageData = await listOfCategoryImagePage(payload);
      } else {
        payload.condition = {
          category_id: selectedCategoryId,
        };
        categoryImageData = await listOfCategoryImagePage(payload);
      }
      const newImages = categoryImageData?.data?.listOfCategoryImage || [];
      setSelectedCategoryImages([...newImages]);
      setVisibleImages(page);
    } catch (error) {
      console.error("Error fetching more images:", error);
    } finally {
      setLoadingImages(false);
    }
  };

  const handleCategoryClick = async (categoryId) => {
    setSelectedCategoryImages([]);
    try {
      setLoadingImages(true);
      setPage(6);
      let categoryImageData;
      if (categoryId === null) {
        categoryImageData = await listOfCategoryImagePage({
          pageSize: 6,
          pageNumber: 1,
        });
      } else {
        categoryImageData = await listOfCategoryImagePage({
          condition: {
            category_id: categoryId,
          },
          pageSize: 6,
          pageNumber: 1,
        });
      }
      setSelectedCategoryImages(categoryImageData?.data?.listOfCategoryImage);
      setSelectedCategoryId(categoryId);
      setTotalRecordsCount(categoryImageData?.data?.totalRecordsCount);

      setVisibleImages(page);
    } catch (error) {
      console.error("Error fetching images by category:", error);
    } finally {
      setLoadingImages(false);
    }
  };

  return (
    <>
      <section className="breadcrumb-area pt-10px bg-gray">
        <div className="container">
          <div className="breadcrumb-content d-flex flex-wrap align-items-center justify-content-between pb-10px">
            <div className="section-heading">
              <h2 className="fs-24 text-black">Gallery</h2>
            </div>
            <ul className=" generic-list-item-black generic-list-item-arrow d-flex flex-wrap align-items-center">
              <li>
                <Link
                  onClick={() => (window.location.href = "/")}
                  to="/"
                  className="text-black"
                >
                  Home
                </Link>
              </li>
              <li>Gallery</li>
            </ul>
          </div>
        </div>
      </section>
      {/* Breadcrumb section */}
      {/* Gallery section */}
      <section className="gallery-area pt-20px">
        <div className="container">
          {/* <div className="row"> */}
          {/* <div className="col-lg-12">
            <div className="section-heading text-center">
              <h2 className="section__title">
                Community that Make Us Stand Out
              </h2>
            </div>
          </div> */}
          {/* Category filter */}
          <ul className="portfolio-filter pt-40px pb-40px">
            {loadingCategories ? (
              <li>Loading...</li>
            ) : (
              <>
                <li
                  data-filter="*"
                  className={selectedCategoryId === null ? "active" : ""}
                  onClick={() => handleCategoryClick(null)}
                >
                  All
                </li>
                {categories.map((category) => (
                  <li
                    key={category.id}
                    onClick={() => handleCategoryClick(category.id)}
                    data-filter={`.${category.category_name}`}
                    className={
                      selectedCategoryId === category.id ? "active" : ""
                    }
                  >
                    {category.category_name}
                  </li>
                ))}
              </>
            )}
          </ul>

          {/* Image grid */}
          <div className="row mb-4">
            {loadingImages ? (
              <div className="d-flex justify-content-center">
                <ClipLoader
                  color={"#000"}
                  loading={true}
                  css={override}
                  size={80}
                />
              </div>
            ) : (
              selectedCategoryImages.map((image) => (
                <div
                  key={image.id}
                  className="col-lg-4 mt-4 image-container galleryImg"
                >
                  <img
                    className="cat__img"
                    src={getProfileImageSrc(
                      image.category_image,
                      imageErrors[image.id]
                    )}
                    onError={() => handleImageError(image.id)}
                    // data-src="images/img1.jpg"
                    alt="Image"
                  />
                </div>
              ))
            )}
          </div>

          {/* Load more button */}
          {selectedCategoryImages.length < totalRecordsCount &&
            (selectedCategoryImages.length > 1) && (
              <div className="text-center">
                <button
                  className="btn theme-btn theme-btn-sm theme-btn-primary mb-3"
                  onClick={() => {
                    const newPageValue = page + 6;
                    setPage(newPageValue);
                    handleLoadMore(newPageValue);
                  }}
                >
                  Load More
                </button>
              </div>
            )}
          {selectedCategoryImages.length < 1 && (
            <div className="card py-4 text-center mb-4 pb-4">
              <div className="mt-4 mb-4">
                <h5>Sorry! No Result Found</h5>
              </div>
            </div>
          )}
          {/* {selectedCategoryImages.length >= totalRecordsCount && (
            <div className="text-center text-danger">
              <p>All records loaded</p>
            </div>
          )} */}
        </div>
      </section>
    </>
  );
};

export default Gallery;
